import {
  ProductUUID,
  FetchProductCacheKey,
  FetchProductGqlQuery,
  ProductNanoID
} from '../../productsTypes';

import { useShowQuery } from '../../../common/hooks/base/reactQuery/useShowQuery';

interface ProductFields {
  uuid: ProductUUID;
}

interface ProductResponse<ItemType extends ProductFields> {
  product: ItemType | null;
}

interface ProductOptions {
  productNanoId: ProductNanoID;
  cacheKey: FetchProductCacheKey;
  query: FetchProductGqlQuery;
  options?: {
    cacheTime?: number;
  };
}

const itemKey = 'product';

function useProduct<ItemType extends ProductFields>({
  productNanoId,
  cacheKey,
  query,
  options = {}
}: ProductOptions) {
  const {
    item,
    itemError,
    itemErrorData,
    itemFetched,
    itemLoading,
    itemIsPlaceholderData
  } = useShowQuery<ProductResponse<ItemType>, ItemType>({
    cacheKey,
    query,
    uuid: productNanoId,
    options,
    itemKey
  });

  return {
    product: item,
    productErrorData: itemErrorData,
    productError: itemError,
    productFetched: itemFetched,
    productLoading: itemLoading,
    productIsPlaceholderData: itemIsPlaceholderData
  };
}

export default useProduct;
