import {
  FetchSelectedProductsFilters,
  FetchSelectedProductsSort,
  FetchSelectedProductsLimit,
  FetchSelectedProductsGqlQuery,
  FetchSelectedProductsCacheKey
} from '../../selectedProductsTypes';

import {
  INITIAL_SELECTED_PRODUCTS_FILTERS,
  INITIAL_SELECTED_PRODUCTS_LIMIT,
  INITIAL_SELECTED_PRODUCTS_SORT
} from '../../selectedProductsConstants';

import {
  useInfiniteIndexQuery,
  InfiniteIndexQueryBaseNodeType
} from '../../../common/hooks/base/reactQuery/useInfiniteIndexQuery';

interface SelectedProductsOptions {
  cacheKey: FetchSelectedProductsCacheKey;
  query: FetchSelectedProductsGqlQuery;
  initialFilters?: FetchSelectedProductsFilters;
  initialSort?: FetchSelectedProductsSort;
  initialLimit?: FetchSelectedProductsLimit;
  options?: {
    enabled?: boolean;
    enabledPlaceholder?: boolean;
    cacheTime?: number;
  };
}

const scope = 'selectedProducts';

function useSelectedProducts<
  SelectedProductItemType extends InfiniteIndexQueryBaseNodeType
>({
  cacheKey,
  query,
  initialFilters = INITIAL_SELECTED_PRODUCTS_FILTERS,
  initialSort = INITIAL_SELECTED_PRODUCTS_SORT,
  initialLimit = INITIAL_SELECTED_PRODUCTS_LIMIT,
  options = {}
}: SelectedProductsOptions) {
  const {
    data,
    items,
    itemsError,
    itemsTotalCount,
    isFetched,
    isLoading,
    isFetchingNextPage,
    isPlaceholderData,
    changeItemsFilters,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    hasNextPage,
    updateItemCache,
    loadMoreItems,
    clearItemsFilters,
    filterItems,
    sortItems,
    limitItems
  } = useInfiniteIndexQuery<SelectedProductItemType>({
    cacheKey,
    initialFilters,
    initialLimit,
    initialSort,
    options,
    query,
    scope
  });

  return {
    selectedProductsData: data,
    selectedProducts: items,
    selectedProductsError: itemsError,
    selectedProductsTotalCount: itemsTotalCount,
    selectedProductsFetched: isFetched,
    selectedProductsLoading: isLoading,
    selectedProductsFetchingNextPage: isFetchingNextPage,
    selectedProductsIsPlaceholderData: isPlaceholderData,
    selectedProductsFilters: currentFilters,
    selectedProductsSort: currentSort,
    selectedProductsPage: currentPage,
    selectedProductsLimit: currentLimit,
    hasNextSelectedProductsPage: hasNextPage,
    updateSelectedProductCache: updateItemCache,
    loadMoreSelectedProducts: loadMoreItems,
    filterSelectedProducts: filterItems,
    clearSelectedProductsFilters: clearItemsFilters,
    sortSelectedProducts: sortItems,
    limitSelectedProducts: limitItems,
    changeSelectedProductsFilters: changeItemsFilters
  };
}

export default useSelectedProducts;
