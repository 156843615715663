import { useCallback } from 'react';
import cl from 'classnames';

import { ID } from '../../../../../../../../../types';
import {
  ItemCreateMessageAttachmentsListAttachFile,
  ItemCreateMessageAttachmentsListImageTypes,
  ItemCreateMessageAttachmentsListSelectLifestyle,
  ItemCreateMessageAttachmentsListSelectProduct,
  ItemCreateMessageAttachmentsListSelectMaterial
} from './ItemCreateMessageAttachmentsListImage.types';

import {
  FileAttachmentItemImageVersions,
  ImageHelper
} from '../../../../../../../../../helpers/ImageHelper';
import { Icon } from '../../../../../../../../../helpers/Icon';
import { PureIconButtonHelper } from '../../../../../../../../../helpers/buttons/PureIconButtonHelper';

import { IconsEnum } from '../../../../../../../../../assets/icons/types';

interface ItemCreateMessageAttachmentsListAttachFileProps {
  attachment: ItemCreateMessageAttachmentsListAttachFile;
  type: ItemCreateMessageAttachmentsListImageTypes.FILE_ATTACHMENT;
}

interface ItemCreateMessageAttachmentsListSelectProductProps {
  attachment: ItemCreateMessageAttachmentsListSelectProduct;
  type: ItemCreateMessageAttachmentsListImageTypes.SELECTED_PRODUCT;
}

interface ItemCreateMessageAttachmentsListSelectLifestyleProps {
  attachment: ItemCreateMessageAttachmentsListSelectLifestyle;
  type: ItemCreateMessageAttachmentsListImageTypes.SELECTED_LIFESTYLE;
}

interface ItemCreateMessageAttachmentsListSelectMaterialProps {
  attachment: ItemCreateMessageAttachmentsListSelectMaterial;
  type: ItemCreateMessageAttachmentsListImageTypes.SELECTED_MATERIAL;
}

type ItemCreateMessageAttachmentsListImageProps = {
  removeImage: (id: ID) => void;
} & (
  | ItemCreateMessageAttachmentsListAttachFileProps
  | ItemCreateMessageAttachmentsListSelectProductProps
  | ItemCreateMessageAttachmentsListSelectLifestyleProps
  | ItemCreateMessageAttachmentsListSelectMaterialProps
);

function ItemCreateMessageAttachmentsListImage({
  attachment,
  type,
  removeImage
}: ItemCreateMessageAttachmentsListImageProps) {
  const handleRemoveImage = useCallback(
    () => removeImage(attachment.id),
    [attachment.id, removeImage]
  );

  const src =
    (type === ItemCreateMessageAttachmentsListImageTypes.FILE_ATTACHMENT &&
      attachment.file) ||
    (type === ItemCreateMessageAttachmentsListImageTypes.SELECTED_PRODUCT &&
      attachment.product.image.file) ||
    (type === ItemCreateMessageAttachmentsListImageTypes.SELECTED_LIFESTYLE &&
      attachment.lifestyle.image.file) ||
    (type === ItemCreateMessageAttachmentsListImageTypes.SELECTED_MATERIAL &&
      attachment.material.image.file);

  const alt =
    (type === ItemCreateMessageAttachmentsListImageTypes.FILE_ATTACHMENT &&
      attachment.name) ||
    (type === ItemCreateMessageAttachmentsListImageTypes.SELECTED_PRODUCT &&
      attachment.product.name) ||
    (type === ItemCreateMessageAttachmentsListImageTypes.SELECTED_LIFESTYLE &&
      attachment.lifestyle.name) ||
    (type === ItemCreateMessageAttachmentsListImageTypes.SELECTED_MATERIAL &&
      attachment.material.name);

  const icon =
    (type === ItemCreateMessageAttachmentsListImageTypes.SELECTED_PRODUCT &&
      IconsEnum.CUBE_OUTLINE) ||
    (type === ItemCreateMessageAttachmentsListImageTypes.SELECTED_LIFESTYLE &&
      IconsEnum.RULERS_OUTLINE) ||
    (type === ItemCreateMessageAttachmentsListImageTypes.SELECTED_MATERIAL &&
      IconsEnum.MATERIAL_OUTLINE);

  return (
    <div className="h-16 relative rounded bg-gray-100 dark:bg-gray-850 group/item w-16">
      <PureIconButtonHelper
        className="absolute top-0 right-0 p-1 rounded-sm sm:opacity-0 group-hover/item:opacity-100 focus:opacity-100 bg-opacity-50 dark:hover:bg-opacity-100 hover:bg-opacity-100 text-gray-700 bg-white dark:bg-gray-900 dark:bg-opacity-50 dark:text-gray-300 focus:ring-offset-0"
        icon={IconsEnum.X}
        iconClassName="h-4 w-4"
        onClick={handleRemoveImage}
      />

      <ImageHelper
        tooltipAlt
        className="w-full h-full rounded object-cover bg-gray-100 dark:bg-gray-850"
        src={src}
        alt={alt}
        version={FileAttachmentItemImageVersions.BigThumb538x435}
      />

      {type !== ItemCreateMessageAttachmentsListImageTypes.FILE_ATTACHMENT && (
        <div
          className={cl(
            'absolute bottom-0 left-0 p-1 text-white flex rounded-bl rounded-tr',
            {
              'bg-indigo-600':
                type ===
                ItemCreateMessageAttachmentsListImageTypes.SELECTED_PRODUCT,
              'bg-pink-600':
                type ===
                ItemCreateMessageAttachmentsListImageTypes.SELECTED_LIFESTYLE,
              'bg-teal-600':
                type ===
                ItemCreateMessageAttachmentsListImageTypes.SELECTED_MATERIAL
            }
          )}
        >
          <Icon icon={icon} className="w-5 h-5" />
        </div>
      )}
    </div>
  );
}

export default ItemCreateMessageAttachmentsListImage;
